#landing-container {
    min-height: 100%;
    overflow: hidden;
    position: relative;
    background-image: url("http://imagecomposer.nfl.com/?f=jpg&w=1000&l=http://i.nflcdn.com/static/content/public/static/img/anthology/landing_bg.png");
    background-color: rgb(0, 0, 0);
    background-size: 100%;
    background-position: center 0px;
    background-repeat: no-repeat;
}

#anthology-landing {
    font-family: 'Endzone Sans Light Cond', 'Helvetica Neue', Arial, sans-serif;
    font-size: 30px;
    margin: auto auto;
    max-width: 1000px;
    text-align: center;
}


