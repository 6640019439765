@font-face {
    font-family: Endzone Sans Light Cond;
    src: url("https://nflcdns.nfl.com/static/content/public/static/wildcat/assets/fonts/endzone-sans/light-cond.woff") format("woff");
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    tap-highlight-color: transparent;
}

:focus {
    outline: none;
}

body {
    background-color: rgb(0, 0, 0);
    font-family: Georgia, Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

